<template> 
    <v-layout justify-center class="pt-9" fill-height> 
        <mbs-page-spinner v-if="!DayShift" />  
        <v-card outlined id="print_template" v-else max-width="300">
            <v-layout column  justify-center  v-if="Company" class="grey--text text--darken-2 py-5 pt- mx-0 px-3 text-center" >
                <div class="font-weight-bold not-f5">{{DayShift.company_name}}</div> 
                <div class="font-weight-bold not-f3">Outlet: {{DayShift.outlet_name}}</div> 
                <div class=" not-f3">Phone: {{Company.company_phone_number}}</div> 
                <div class=" not-f3">Email: {{Company.company_email_address}}</div> 
                <div class=" not-f3">Location Address: {{Company.company_location_address}}</div> 
            </v-layout>
             

            <div class="mx-3"> 
                <!-- //DETAILS --> 
                <v-card flat="" class="pa-1" outlined="" tile="">
                    <div class="text-center font-weight-bold">{{sales_type?sales_type:'X-REPORT'}}</div> 
                </v-card>

                <div class="text-center mb-2 mt-3 ">DAY SHIFT DETAILS</div>
                <div> 
                    <v-layout row wrap  class="ma-0">
                        <div>Day:</div>
                        <v-spacer></v-spacer>
                        <div class="font-weight-bold">{{MBS.date.moment(DayShift.operating_date).format("ddd, Do, MMMM YYYY")}}</div> 
                    </v-layout>
                    <v-layout row wrap  class="ma-0">
                        <div>Open at :</div> 
                        <v-spacer></v-spacer>
                        <div>{{DayShift.local_created_at_}}</div> 
                    </v-layout>
                    <v-layout row wrap  class="ma-0">
                        <div>Open By :</div> 
                        <v-spacer></v-spacer>
                        <div>{{DayShift.user_name}}</div> 
                    </v-layout>
                    <v-layout row wrap  class="ma-0">
                        <div>Close at :</div> 
                        <v-spacer></v-spacer>
                        <div>
                            <div v-if="DayShift.total_closing_cash">{{DayShift.updated_at_}}</div>
                            <div v-else-if="closing_cash">{{DayShift.local_updated_at_}}</div>
                            <div v-else>[<strong> Running... </strong>]</div> 
                        </div>  
                    </v-layout>
                    <v-layout v-if="DayShift.total_closing_cash" row wrap  class="ma-0">
                        <div>Close By :</div> 
                        <v-spacer></v-spacer>
                        <div>{{DayShift.close_user_name}}</div> 
                    </v-layout>
                    <v-layout row wrap  class="ma-0">
                        <div>Tills:</div> 
                        <v-spacer></v-spacer>
                        [ <div v-for="(till,index) in DayShift.joined_tills" :key="index">{{till.name}},</div> ]
                    </v-layout>
                    <v-layout row wrap  class="ma-0">
                        <div>Floating Cash:</div> 
                        <v-spacer></v-spacer>
                        <div>MWK {{DayShift.total_floating_cash_}}</div> 
                    </v-layout> 
                </div> 
                
                
            
                <!-- //CASHUPS -->
                <v-divider class=" mt-5 mb-3" ></v-divider>
                <div class="text-center mb-2">CASHUP(S) / REGISTER(S)</div>
                <div>
                    <div  v-for="(cashup,index) in Joined_Cashups" :key="index" 
                        class="mx- mb-5">
                        <v-layout class="ma-0 ">
                            <div>Teller:</div> 
                            <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                            <div>{{cashup.teller_name}}</div>  
                        </v-layout> 
                        <v-layout class="ma-0 ">
                            <div>Till:</div> 
                            <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                            <div>{{cashup.till_name}}</div>  
                        </v-layout> 
                        <v-layout class="ma-0 ">
                            <div>Open at:</div> 
                            <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                            <div>{{cashup.local_created_at_}}</div>  
                        </v-layout> 
                        <v-layout class="ma-0 ">
                            <div>Closed at:</div> 
                            <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                            <div>
                                <div v-if="cashup.closed">{{cashup.close_date_time_}}</div>
                                <div v-else>[<strong> Running... </strong>]</div> 
                            </div>  
                        </v-layout> 
                        <!-- <v-layout class="ma-0 ">
                            <div>Floating Cash:</div> 
                            <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                            <div>MWK {{cashup.floating_cash_?cashup.floating_cash_:"..."}}</div>  
                        </v-layout>  -->
                        <v-layout class="ma-0 ">
                            <div>Opening Balance:</div> 
                            <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                            <div>MWK {{cashup.opening_cash_?cashup.opening_cash_:"..."}}</div>  
                        </v-layout> 
                        <v-layout class="ma-0 ">
                            <div>Cash Sales:</div> 
                            <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                            <div>MWK 
                                <span v-if="cashup.sales_cash_payments">{{MBS.actions.money(cashup.sales_cash_payments)}}</span>
                                <span v-else>{{MBS.actions.money(TOTAL_SOLD(CashCashupItems(cashup.key)))}}</span>
                            </div>  
                        </v-layout> 
                        <v-layout class="ma-0 ">
                            <div>Other Sales:</div> 
                            <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                            <div>MWK 
                                <span v-if="cashup.sales_total_other_option_payments">{{MBS.actions.money(cashup.sales_total_other_option_payments)}}</span>
                                <span v-else>{{MBS.actions.money(TOTAL_SOLD(OtherOptionsCashupItems(cashup.key)))}}</span>
                            </div>  
                        </v-layout> 
                        <v-layout class="ma-0 ">
                            <div>On Account:</div> 
                            <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                            <div>MWK 
                            <span v-if="cashup.sales_on_account">{{MBS.actions.money(cashup.sales_on_account)}}</span>
                                <span v-else>{{MBS.actions.money(TOTAL_SOLD(OnAccountCashupItems(cashup.key)))}}</span>
                            </div>  
                        </v-layout> 
                        <v-layout class="ma-0 "  v-if="cashup.invoices_cash_payments || TotalInvoicePayments">
                            <div>Invoice Payments:</div> 
                            <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                            <div>MWK 
                                <span v-if="cashup.invoices_cash_payments">
                                {{MBS.actions.money(
                                    cashup.invoices_cash_payments
                                    +cashup.invoices_total_other_option_payments
                                )}}</span>
                                <span v-else>{{MBS.actions.money(TotalInvoicePayments)}}</span>
                            </div>  
                        </v-layout> 
                        <v-layout class="ma-0 ">
                            <div>Total Sales:</div> 
                            <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                            <div>MWK 
                                <span v-if="cashup.sales_cash_payments||cashup.sales_on_account">
                                    {{MBS.actions.money(toNumber(cashup.sales_cash_payments)+toNumber(cashup.sales_total_other_option_payments)+toNumber(cashup.sales_on_account))}}
                                </span>
                                <span v-else>{{MBS.actions.money(TOTAL_SOLD(CashupOrdersItems(cashup.key)))}}</span>
                            </div>  
                        </v-layout> 
                        <v-layout class="ma-0 " v-if="cashup.closed">
                            <div>Closing Cash:</div> 
                            <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                            <div>MWK {{cashup.closing_cash_?cashup.closing_cash_:"0.00"}}</div>  
                        </v-layout> 
                        <v-layout class="ma-0 " v-if="cashup.closed && ShowShortagesSettings">
                            <v-layout class="ma-0 " 
                                v-if="SHORTAGE(cashup)">
                                <div>Shortage:</div> 
                                <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                                (<div class="px-1 font-weight-bold"> MWK {{MBS.actions.money(SHORTAGE(cashup))}} </div> )
                            </v-layout>
                            <v-layout class="ma-0 " v-if="OVERAGE(cashup)">
                                <div>Overage:</div> 
                                <v-spacer class="mbs-dashed-line mx-1 mt-3"></v-spacer>
                                <div class="px-1 font-weight-bold">MWK {{MBS.actions.money(OVERAGE(cashup))}}</div>
                            </v-layout>
                        </v-layout> 
                    </div> 
                </div>

                <!-- //CLOSING REPORT --> 
                <div v-if="SalesOtherOptionPayments.options">
                    <v-divider class=" mt-5 mb-3" ></v-divider> 
                    <div class="text-center mb-2">PAYMENT OPTION(S)</div>
                    <v-layout row wrap  class="ma-0" v-for="(option,index) in AllOtherOptionPayments.options" :key="index">
                        <div>{{option.payment_method_name}}</div> 
                        <v-spacer></v-spacer> 
                        <div >MWK {{MBS.actions.money(option?option.amount:0)}}</div>  
                    </v-layout>
                    <v-layout  row wrap class="ma-0">
                        <div >TOTAL:</div> 
                        <v-spacer></v-spacer>  
                        <v-spacer></v-spacer> 
                        <div >MWK. {{MBS.actions.money(AllOtherOptionPayments.total)}}</div> 
                         
                    </v-layout>
                </div>

                <!-- //CLOSING REPORT --> 
                <v-divider class=" mt-5 mb-3" ></v-divider> 
                <div class="text-center mb-2">CASHUP(S) SUMMARY</div>
                <div> 
                    <v-layout row wrap  class="ma-0">
                        <div>Retail:</div> 
                        <v-spacer></v-spacer>
                        <div v-if="TotalCashups.sales_retail>TOTAL_SOLD(RetailDayShiftItems)">
                            MWK {{MBS.actions.money(TotalCashups.sales_retail)}}
                        </div>  
                        <div v-else>MWK {{MBS.actions.money(TOTAL_SOLD(RetailDayShiftItems))}}</div>  
                    </v-layout>
                    <v-layout row wrap  class="ma-0">
                        <div>Wholesale:</div> 
                        <v-spacer></v-spacer>
                        <div v-if="TotalCashups.sales_wholesales>TOTAL_SOLD(WholesaleDayShiftItems)">
                            MWK {{MBS.actions.money(TotalCashups.sales_wholesales)}}
                        </div> 
                        <div v-else>MWK {{MBS.actions.money(TOTAL_SOLD(WholesaleDayShiftItems))}}</div> 
                    </v-layout>
                    <v-layout row wrap  class="ma-0">
                        <div>Cash Sales:</div> 
                        <v-spacer></v-spacer>
                        <div v-if="TotalCashups.sales_cash_payments>TOTAL_SOLD(CashDayShiftItems)">
                            MWK {{MBS.actions.money(TotalCashups.sales_cash_payments)}}
                        </div> 
                        <div v-else>MWK {{MBS.actions.money(TOTAL_SOLD(CashDayShiftItems))}}</div> 
                    </v-layout>
                    <v-layout row wrap  class="ma-0">
                        <div>Other Sales:</div> 
                        <v-spacer></v-spacer>
                        <div >
                            MWK {{MBS.actions.money(SalesOtherOptionPayments.total)}}
                        </div>  
                    </v-layout>
                    <v-layout row wrap  class="ma-0">
                        <div>On Account:</div> 
                        <v-spacer></v-spacer>
                        <div v-if="TotalCashups.sales_on_account>TOTAL_SOLD(OnAccountDayShiftItems)">
                            MWK {{MBS.actions.money(TotalCashups.sales_on_account)}}
                        </div> 
                        <div v-else>MWK {{MBS.actions.money(TOTAL_SOLD(OnAccountDayShiftItems))}}</div> 
                    </v-layout>
                    <v-layout row wrap  class="ma-0">
                        <div>Cash Invoice:</div> 
                        <v-spacer></v-spacer>
                        <div v-if="TotalCashups.invoices_cash_payments>TotalInvoicePayments">
                            MWK {{MBS.actions.money(TotalCashups.invoices_cash_payments)}}
                        </div> 
                        <div v-else>MWK {{MBS.actions.money(TOTAL_AMOUNT(CashInvoicePayments))}}</div> 
                    </v-layout>
                    <v-layout row wrap  class="ma-0">
                        <div>Other Invoice:</div> 
                        <v-spacer></v-spacer> 
                        <div>MWK {{MBS.actions.money(InvoiceOtherOptionPayments.total)}}</div> 
                    </v-layout>

                    <v-layout  row wrap class="ma-0">
                        <div   >TOTAL SALES:</div> 
                        <v-spacer></v-spacer>  
                        <v-spacer></v-spacer> 
                        <div v-if="MBS.actions.SIZE(UnClosedCashups)>0">[<strong> Waiting.. </strong>]</div>  
                        <div v-else>
                            <div v-if="(TotalCashups.sales_retail+TotalCashups.sales_wholesales)>TOTAL_SOLD(DayShiftSaleOrdersItems)">
                                MWK {{MBS.actions.money(TotalCashups.sales_retail+TotalCashups.sales_wholesales)}}
                            </div> 
                            <div v-else>MWK. {{MBS.actions.money(TOTAL_SOLD(DayShiftSaleOrdersItems))}}</div> 
                        </div>
                    </v-layout>
                </div> 

                
                
                <!-- //SHOW SHORTAGES REPORT --> 
                <div v-if="ShowShortagesSettings">
                    <v-divider class=" mt-5 mb-3" ></v-divider> 
                    <div class="text-center mb-2">SHORTAGE(S) / OVERAGE(S)</div>
                    <div> 
                        <v-layout row wrap  class="ma-0">
                            <div>Shortage:</div> 
                            <v-spacer></v-spacer>
                            (<div>MWK {{MBS.actions.money(AllShortages)}}</div> ) 
                        </v-layout>
                        <v-layout row wrap  class="ma-0">
                            <div>Overage:</div> 
                            <v-spacer></v-spacer>
                            <div>MWK {{MBS.actions.money(AllOverages)}}</div> 
                        </v-layout>
                        <v-layout row wrap  class="ma-0">
                            <div>Total :</div> 
                            <v-spacer></v-spacer>
                            <div v-if="AllShortages>AllOverages">
                                (<strong> MWK {{MBS.actions.money((AllOverages-AllShortages)*-1)}} </strong>)
                            </div> 
                            <div v-else><strong> MWK {{MBS.actions.money((AllOverages-AllShortages))}} </strong></div>
                        </v-layout> 
                    </div>  
                </div>

                <!-- //TOTALS --> 
                <v-divider class=" mt-5 mb-3" ></v-divider>  
                <div class="font-weight-bold "> 
                    
                    <v-layout   class="ma-0" v-if="(AllOverages-AllShortages) != 0">
                        <div >EXPECTED:</div> 
                        <v-spacer></v-spacer> 
                        <div v-if="MBS.actions.SIZE(UnClosedCashups)>0">[<strong> Waiting.. </strong>]</div>  
                        <div v-else>
                            MWK {{MBS.actions.money(TotalCashups.sales_cash_payments+TotalCashups.invoices_cash_payments+DayShift.total_floating_cash)}}
                        </div>  
                    </v-layout>
                    <v-layout   class="ma-0">
                        <div >TOTAL CASH:</div> 
                        <v-spacer></v-spacer> 
                        <div v-if="MBS.actions.SIZE(UnClosedCashups)>0">[<strong> Waiting.. </strong>]</div>  
                        <div v-else>
                            MWK {{MBS.actions.money((TotalCashups.sales_cash_payments+TotalCashups.invoices_cash_payments+DayShift.total_floating_cash)+(AllOverages-AllShortages))}}
                        </div>  
                    </v-layout>
                    <v-layout   class="ma-0">
                        <div >FLOAT:</div> 
                        <v-spacer></v-spacer> 
                        <div>MWK {{DayShift.total_floating_cash_}}</div> 
                    </v-layout>
                    
                    <!-- <v-layout   class="ma-0">
                        <div >CASH SALES:</div> 
                        <v-spacer></v-spacer> 
                        <div>MWK {{MBS.actions.money(TOTAL_SOLD(CashDayShiftItems))}}</div>  
                    </v-layout> -->
                    
                    <v-layout    class="ma-0">
                        <div >CLOSING CASH:</div>  
                        <v-spacer></v-spacer>  
                        <div v-if="DayShift.total_closing_cash">MWK {{MBS.actions.money(DayShift.total_closing_cash)}}</div>
                        <div v-else-if="closing_cash">{{MBS.actions.money(closing_cash)}}</div>
                        <div v-else>[<strong> Waiting.. </strong>]</div> 
                    </v-layout> 
                </div>  
            </div> 
             
             
            <v-divider class="mx-3 mt-9 mb-2"></v-divider> 
            <v-layout column class="text-center" >
                <div class="font-weight-bold not-f3">POWERED BY KIPCOUNT</div>
                <div class="font-weight-bold not-f3">{{DATA.APP.ADMIN_PHONE}}</div> 
            </v-layout> 
            <br><br>
        </v-card>
    </v-layout> 
</template>

<script>
    import DATA from "../../../plugins/DATA"
    import {mapState } from "vuex"
    let PAGE_NAME  = 'DAY-SHIFT-TEMPLATE-1'
    export default {
        props:['data','sales_type','closing_cash'], 
        data(){
            return{
                show:true,
                DATA:DATA,
                printSVG:null,
                inputLoading:true
            } 
        }, 
        mounted(){ 
        },
        computed:{
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                Payments: state=> state.items[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],
                JoinedPayments: state=> state.join[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],  
                SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
                JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],  
                LocalSaleOrders: state=> state.items[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                JoinedLocalSaleOrders: state=> state.join[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
            }), 
            DataInvoicePayments(){
                let Payments = this.Payments
                let JoinedPayments = this.JoinedPayments 
                return JoinedPayments?JoinedPayments:Payments
            },
            DataSaleOrders(){
                let SaleOrders = this.SaleOrders
                let JoinedSaleOrders = this.JoinedSaleOrders 
                return JoinedSaleOrders?JoinedSaleOrders:SaleOrders
            },
            AllLocalSaleOrdersData(){  
                const LocalSaleOrders = this.LocalSaleOrders
                const JoinedLocalSaleOrders = this.JoinedLocalSaleOrders
                let items = JoinedLocalSaleOrders?JoinedLocalSaleOrders:LocalSaleOrders  
                if(!items){return null} 
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",true)) 
                return list
            }, 
            BothSaleOrders(){
                let orders = this.DataSaleOrders
                let localOrders = this.AllLocalSaleOrdersData
                if(!orders && !localOrders){return null}
                if(orders && !localOrders){return orders}
                if(!orders && localOrders){return localOrders}

                let both = [...orders]
                localOrders.forEach(element => {
                    let item = orders.find(item=>{
                        return item.key == element.key
                    })
                    if (!item) {
                        both.push(element)
                    } 
                });
                let list = [...both]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false)) 
                return list
            },
            ShowShortagesSettings(){
                let settings = this.CompanySettings
                let show_shortages = settings?settings.show_shortages:false 
                return show_shortages
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            DayShift(){ 
                let data = this.data
                console.log(data,'day......');  
                return  data
            },
            Company(){ 
                let item = this.DayShift
                return item?item.company:null
            }, 
            Cashups(){ 
                let item = this.DayShift 
                return item?item.cashups:null
            }, 
            CashupSalesOtherOptionPayments(){ 
                let cashups = this.Cashups 
                if(!cashups){return null}
                let options = []
                for (const cashup_key in cashups) {
                    const element = cashups[cashup_key]; 
                    let other_option_payments = element.sales_other_option_payments
                    if (other_option_payments) {
                        for (const key in other_option_payments) {
                            const other_option = other_option_payments[key]; 
                            let index = options.findIndex(option=>{
                                return option.payment_option_code  == key
                            }) 
                            if (index == -1) {
                                let payment_method = this.$store.getters.getPaymentMethodByCode(key)
                                options.push({
                                    ...other_option,
                                    payment_method:payment_method,
                                    payment_method_name:payment_method?payment_method.name:"---", 
                                })
                            } else {
                                options[index].amount = options[index].amount+this.toNumber(other_option.amount)
                            }  
                        }
                    }  
                } 
                return this.MBS.actions.SIZE(options)>0?options:null
            }, 
            Joined_Cashups(){ 
                let item = this.DayShift  
                let cashups = item?item.joined_cashups:null   
                return cashups
            }, 
            TotalCashups(){ 
                let items = this.Joined_Cashups 
                let total = this.MBS.actions.CALCULATE_TOTAL_CASHUPS(items)  
                return total
            }, 
            ClosedCashups(){ 
                let items = this.Joined_Cashups 
                if(!items){return null} 
                let filtered = items.filter(cashup=>{
                    return cashup.closed
                })
                return filtered
            }, 
            UnClosedCashups(){ 
                let items = this.Joined_Cashups 
                if(!items){return null} 
                let filtered = items.filter(cashup=>{
                    return !cashup.closed
                })
                return filtered
            }, 
            
            AllShortages(){ 
                let items = this.Joined_Cashups 
                if(!items){return null}
                let shortage = 0
                items.forEach(cashup => {
                    shortage = shortage + this.SHORTAGE(cashup)
                });
                return shortage
            }, 
            AllOverages(){ 
                let items = this.Joined_Cashups 
                if(!items){return null}
                let overage = 0
                items.forEach(cashup => {
                    overage = overage + this.OVERAGE(cashup)
                });
                return overage
            }, 

            
             
            DayShiftSaleOrders(){  
                let sales = this.BothSaleOrders
                let DayShift = this.DayShift
                let day_shift_key = DayShift?DayShift.key:null 
                if(!day_shift_key||!sales){return null}
                let filtered_items = sales.filter(item=>{
                    return item.day_shift_key == day_shift_key
                })   
                return filtered_items  
            }, 
            DayShiftSaleOrdersItems(){
                let sales = this.DayShiftSaleOrders 
                // this.LOG_TTT(sales)
                let items = this.ORDER_ITEMS(sales) 
                // this.LOG_TRACE(items)
                return items
            },
            RetailDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems
                let retail_items = this.RETAIL_ITEMS(items)
                return retail_items 
            },
            WholesaleDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems
                let retail_items = this.WHOLESALE_ITEMS(items)
                return retail_items 
            },
            CashDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems
                let retail_items = this.CASH_ITEMS(items)
                return retail_items 
            },
            OtherOptionsDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems
                let retail_items = this.OTHER_OPTIONS_ITEMS(items)
                console.log(retail_items,'retail_items...');
                return retail_items 
            },
            OnAccountDayShiftItems(){ 
                let items = this.DayShiftSaleOrdersItems
                let retail_items = this.ON_ACCOUNT_ITEMS(items)
                return retail_items 
            },
            
            CashupOrders(){
                let orders = this.DayShiftSaleOrders
                return cashup_key =>{
                    if(!orders){return null}
                    return orders.filter(order=>{
                        return order.cashup_key == cashup_key
                    })
                }
            },
            CashupOrdersItems(){
                return (cashup_key)=>{
                    let sales = this.CashupOrders(cashup_key)
                    let items = this.ORDER_ITEMS(sales) 
                    return items 
                }
            },
            RetailCashupItems(){ 
                return (cashup_key)=>{
                    let items = this.CashupOrdersItems(cashup_key)
                    let retail_items = this.RETAIL_ITEMS(items)
                    return retail_items  
                }
            },
            WholesaleCashupItems(){ 
                return (cashup_key)=>{
                    let items = this.CashupOrdersItems(cashup_key)
                    let retail_items = this.WHOLESALE_ITEMS(items)
                    return retail_items  
                }
            },
            CashCashupItems(){ 
                return (cashup_key)=>{
                    let items = this.CashupOrdersItems(cashup_key)
                    let retail_items = this.CASH_ITEMS(items) 
                    return retail_items  
                }
            },
            OtherOptionsCashupItems(){ 
                return (cashup_key)=>{
                    let items = this.CashupOrdersItems(cashup_key)
                    let retail_items = this.OTHER_OPTIONS_ITEMS(items) 
                    return retail_items  
                }
            },
            OnAccountCashupItems(){ 
                return (cashup_key)=>{
                    let items = this.CashupOrdersItems(cashup_key)
                    let retail_items = this.ON_ACCOUNT_ITEMS(items)
                    return retail_items  
                }
            }, 
            TotalClosingCash(){
                let cashups = this.Joined_Cashups
                if(!cashups){return null}
                let total = 0
                cashups.forEach(element => {
                    total = total+this.toNumber(element.closing_cash)
                });
                return total
            }, 
            ThisInvoicePayments(){
                let payments = this.DataInvoicePayments
                let DayShift = this.DayShift
                let day_shift_key = DayShift?DayShift.key:null
                if(!payments||!day_shift_key){return null}
                let filled_items = payments.filter(item=>{
                    return item.day_shift_key == day_shift_key
                }) 
                return filled_items
            },
            CashInvoicePayments(){
                let payments = this.ThisInvoicePayments 
                if(!payments){return null}
                let filled_items = payments.filter(item=>{
                    return !item.payment_option_code
                }) 
                return filled_items
            },
            OtherInvoicePayments(){  
                let payments = this.ThisInvoicePayments 
                if(!payments){return null}
                let filled_items = payments.filter(item=>{
                    return item.payment_option_code
                })  
                return filled_items
            },
            TotalInvoicePayments(){
                try { 
                    let items = this.ThisInvoicePayments
                    let total_amount = 0 
                    if (!items) {return 0}
                    items.forEach(item => {
                        let amount = this.toNumber(item.amount) 
                        total_amount = total_amount + amount
                    });  
                    return total_amount
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalInvoicePayments',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },  
            SalesOtherOptionPayments(){
                //Cashup Data
                let TotalCashups = this.TotalCashups
                let sales_other_option_payments = TotalCashups?TotalCashups.sales_other_option_payments:null
                let sales_total_other_option_payments = TotalCashups?TotalCashups.sales_total_other_option_payments:0 
                 
                //Other Data
                let items = this.OtherOptionsDayShiftItems   
                let total_item_options = 0
                let options = []
                if (items) {
                    items.forEach(element => {
                        let payment_option_code = element.payment_option_code
                        let sold_price = this.toNumber(element.sold_price)
                        let quantity = this.toNumber(element.quantity)
                        let discount = this.toNumber(element.discount)
                        let price = (sold_price-discount) *quantity

                        let index = options.findIndex(option=>{
                            return option.payment_option_code  == payment_option_code
                        }) 
                        if (payment_option_code) {
                            if (index == -1) {
                                let payment_method = this.$store.getters.getPaymentMethodByCode(payment_option_code)
                                options.push({
                                    payment_method:payment_method,
                                    payment_method_name:payment_method?payment_method.name:"---",
                                    payment_option_code:payment_option_code,
                                    amount:price
                                })
                            } else {
                                options[index].amount = options[index].amount+price
                            }
                            total_item_options = total_item_options+price
                        } 
                    });   
                }
                return sales_total_other_option_payments>total_item_options?{
                    options:sales_other_option_payments,
                    total:sales_total_other_option_payments,
                }:{
                    options:options,
                    total:total_item_options
                }
            },  
            InvoiceOtherOptionPayments(){
                //From Cashup Data
                let TotalCashups = this.TotalCashups
                let invoices_other_option_payments = TotalCashups?TotalCashups.invoices_other_option_payments:null
                let invoices_total_other_option_payments = TotalCashups?TotalCashups.invoices_total_other_option_payments:0 
                 
                //Offline
                let items = this.OtherInvoicePayments   
                let total_item_options = 0
                let options = []
                if (items) {
                    items.forEach(element => {
                        let payment_option_code = element.payment_option_code
                        let index = options.findIndex(option=>{
                            return option.payment_option_code  == payment_option_code
                        }) 
                        if (payment_option_code) {
                            if (index == -1) {
                                let payment_method = this.$store.getters.getPaymentMethodByCode(payment_option_code)
                                options.push({
                                    payment_method:payment_method,
                                    payment_method_name:payment_method?payment_method.name:"---",
                                    payment_option_code:payment_option_code,
                                    amount:this.toNumber(element.amount)
                                })
                            } else {
                                options[index].amount = options[index].amount+this.toNumber(element.amount) 
                            }
                            total_item_options = total_item_options+this.toNumber(element.amount)
                        } 
                    });   
                }
                return invoices_total_other_option_payments>total_item_options?{
                    options:invoices_other_option_payments,
                    total:invoices_total_other_option_payments,
                }:{
                    options:options,
                    total:total_item_options
                }
            },  
            AllOtherOptionPayments(){
                //From Cashup Data
                let SalesOtherOptionPayments = this.SalesOtherOptionPayments
                let InvoiceOtherOptionPayments = this.InvoiceOtherOptionPayments
                let options = [] 

                if (SalesOtherOptionPayments.options) {
                    options = [...SalesOtherOptionPayments.options]
                }if (InvoiceOtherOptionPayments.options) {
                    InvoiceOtherOptionPayments.options.forEach(element => {
                        let exist = options.findIndex(item=>item.payment_option_code==element.payment_option_code)
                        if (exist != -1) {
                            options[exist].amount = options[exist].amount+element.amount
                        } else {
                            options.push(element) 
                        } 
                    }); 
                } 

                console.log(options,'options....');
                return {
                    options:options,
                    total:this.toNumber(SalesOtherOptionPayments.total)+this.toNumber(InvoiceOtherOptionPayments.total)
                }
            },  
        },
        methods:{
            TOTAL_AMOUNT(items){
                try {  
                    let total_amount = 0 
                    if (!items) {return 0}
                    items.forEach(item => {
                        let amount = this.toNumber(item.amount) 
                        total_amount = total_amount + amount
                    });  
                    return total_amount
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TOTAL_AMOUNT',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            },  
            LOG_TTT(items){
                try { 
                    console.log("[---------------------]");
                    let total_cash = 0
                    let total_on = 0
                    console.log(items,'sssss');

                    for (const element of items) {
                        let total_cost_price = element.total_sold_price
                        let total_cost_price_ = element.total_sold_price_
                        let quantity = element.quantity
                        let account_type = element.account_type
                        let teller_name = element.teller_name
                        let key = element.key
                        let items = element.items
                        if (account_type === "cash") {
                            console.log(key,": ",account_type,":... qyt: ",total_cost_price_,teller_name);
                            total_cash = total_cash+total_cost_price

                            let total_sold_price = 0
                            for (const item of items) {
                                let sold_price = this.toNumber(item.sold_price)
                                let discount = this.toNumber(item.discount)
                                let quantity = this.toNumber(item.quantity)
                                total_sold_price = total_sold_price+((sold_price-discount)*quantity)
                            }
                            console.log("item price:. "+this.MBS.actions.money(total_sold_price));
                        }  
                        
                    }
                    
                    for (const element of items) {
                        let total_cost_price = element.total_sold_price
                        let total_cost_price_ = element.total_sold_price_
                        let quantity = element.quantity
                        let account_type = element.account_type
                        let teller_name = element.teller_name
                        let key = element.key
                        let items = element.items

                        if (account_type != "cash") {
                            console.log(key,": ",account_type,":... qyt: ",total_cost_price_,teller_name);
                            total_on = total_on+total_cost_price

                            let total_sold_price = 0
                            for (const item of items) {
                                let sold_price = this.toNumber(item.sold_price)
                                let discount = this.toNumber(item.discount)
                                let quantity = this.toNumber(item.quantity)
                                total_sold_price = total_sold_price+((sold_price-discount)*quantity)
                            }
                            console.log("item price:. "+this.MBS.actions.money(total_sold_price));
                        }  
                    }
 
                    console.log(this.MBS.actions.money(total_cash),'total_cash...'); 
                    console.log(this.MBS.actions.money(total_on),'total_on...'); 

                    console.log("[---------------------]");
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SHORTAGE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            
            LOG_TRACE(items){
                try { 
                    console.log("[---------------------]");
                    let total_cash = 0
                    let total_on = 0
                    console.log(items,'sssss');

                    for (const element of items) {
                        let sold_price = element.sold_price
                        let quantity = element.quantity
                        let account_type = element.account_type
                        let discount = element.discount
                        let key = element.item_keys
                        if (account_type === "cash") {
                            console.log(key,": ",account_type,":... ",sold_price);
                            total_cash = total_cash+((sold_price-discount)*quantity)
                        }  
                    }

                    for (const element of items) {
                        let sold_price = element.sold_price
                        let quantity = element.quantity
                        let account_type = element.account_type 
                        let discount = element.discount
                        let key = element.item_key

                        if (account_type != "cash") {
                            console.log(key,": ",account_type,":... ",sold_price);
                            total_on = total_on+((sold_price-discount)*quantity)
                        }    
                    }
                      
                     
                    console.log(total_cash,'total_cash...');
                    console.log(total_on,'total_on...');
                    console.log("[---------------------]");
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SHORTAGE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            
            SHORTAGE(cashup){
                try { 
                    if(!cashup){return 0} 
                    if (!cashup.closed) {
                        return 0
                    }
                    let sales_cash_payments = this.toNumber(cashup.sales_cash_payments)
                    let sales_on_account = this.toNumber(cashup.sales_on_account)
                    let invoices_cash_payments = this.toNumber(cashup.invoices_cash_payments)
                    let closing_cash = this.toNumber(cashup.closing_cash)
                    let opening_cash = this.toNumber(cashup.opening_cash)
                    let total_cash = sales_cash_payments+opening_cash+invoices_cash_payments
                    let def =  closing_cash-total_cash
 
                    if (total_cash>closing_cash) {
                       return def*-1
                    }
                    return 0
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SHORTAGE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            OVERAGE(cashup){
                try { 
                    if(!cashup){return 0}
                    if (!cashup.closed) {
                        return 0
                    }
                    let sales_cash_payments = this.toNumber(cashup.sales_cash_payments)
                    let sales_on_account = this.toNumber(cashup.sales_on_account)
                    let invoices_cash_payments = this.toNumber(cashup.invoices_cash_payments)
                    let closing_cash = this.toNumber(cashup.closing_cash)
                    let opening_cash = this.toNumber(cashup.opening_cash)
                    let total_cash = sales_cash_payments+opening_cash+invoices_cash_payments
                    let def =  closing_cash-total_cash
                    if (total_cash<closing_cash) {
                       return def 
                    }
                    return 0
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'OVERAGE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },  
            CALCULATE_PRICE(item){
                try { 
                    if (!item) {return 0}
                    let sold_price = this.toNumber(item.sold_price)
                    let discount = this.toNumber(item.discount)
                    let quantity = this.toNumber(item.quantity)
                    return quantity*(sold_price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },   
            ORDER_ITEMS(orders){  
                try { 
                    if(!orders){return null} 
                    let items = []
                    orders.forEach(element => {
                        let joined_items = element.joined_items
                        let payment_option_code = element.payment_option_code
                        if (joined_items) {
                            joined_items.forEach(item => {
                                items.push({
                                    ...item,
                                    payment_option_code:payment_option_code,
                                    account_type:element.account_type
                                }) 
                            }); 
                        }
                    }); 
                    return items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ORDER_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            RETAIL_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return this.MBS.actions.TEXT_UP0(this.MBS.actions.TEXT_SPACE0(element.selling_model)) ==  
                        this.MBS.actions.TEXT_SPACE0("retail price")
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'RETAIL_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            WHOLESALE_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return this.MBS.actions.TEXT_UP0(this.MBS.actions.TEXT_SPACE0(element.selling_model)) ==  
                        this.MBS.actions.TEXT_SPACE0("wholesale price")
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'WHOLESALE_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            CASH_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return element.account_type == "cash"
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CASH_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            OTHER_OPTIONS_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return element.account_type == "payment_option"
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'OTHER_OPTIONS_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            ON_ACCOUNT_ITEMS(items){  
                try { 
                    if(!items){return null}
                    let filtered_items = items.filter(element => {
                        return element.account_type == "on_account"
                    });
                    return filtered_items
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_ACCOUNT_ITEMS',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            TOTAL_SOLD(items){
                try {  
                    let total = 0
                    if (items) {
                        if(!items){return null}
                        items.forEach(element => {
                            let sold_price = this.toNumber(element.sold_price)
                            let quantity = this.toNumber(element.quantity)
                            let discount = this.toNumber(element.discount)
                            total = total+((sold_price-discount)*quantity)
                        }); 
                    }
                    return total
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TOTAL_SOLD',
                        page:PAGE_NAME, 
                    })
                }  
            },  
        },
        watch:{
            mbs_dialog(value){ 
            },   
        } 
    }
</script>
